@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Popins;
        src: url("./Assets/Fonts/Poppins-Medium.ttf");
    }

    * {
        padding: 0;
        left: 0;
        box-sizing: border-box;
    }

    .sidebar-container {
        @apply bg-[#002E60] h-[100%] w-[14rem] p-2 transition-all duration-700 border-glass relative
    }

    .sidebar-container-close {
        @apply bg-[#002E60] h-[100%] w-[6rem] p-4 transition-all duration-700 relative
    }

    .sidebar {
        @apply flex items-center mt-1 p-2 rounded-lg transition-all duration-300 cursor-pointer last:absolute left-4 bottom-4
    }
}